import { Button } from '@/components/ui/atoms/button'
import { STUDENT_AREA_URL, SUBSCRIPTION } from '@/consts'
import { cn, navigate } from '@/lib/utils'
import {
  actions as authentication_action,
  store as authentication_store,
} from '@/store/authentication'
import { store as checkout_store } from '@/store/checkout'
import type { IAccount, IAuthentication } from '@/types/account'
import type { INavigationItem } from '@/types/navigation'
import { useStore } from '@nanostores/react'
import {
  ArrowUpRight,
  ChevronDown,
  ChevronRight,
  LogOut,
  Menu,
  MessageCircleQuestion,
  RotateCw,
  ShoppingBasket,
  SquareArrowOutUpRight,
  X,
  Zap,
} from 'lucide-react'
import { atom } from 'nanostores'
import type React from 'react'
import { useEffect, useState } from 'react'
import UserDropdownMenu from './user-dropdown-menu'

const menuAtom = atom<boolean>(false)

export default function HeaderEnhanced({
  navigation,
}: { navigation: INavigationItem[] }) {
  const $open = useStore(menuAtom)
  const $cart = useStore(checkout_store.cart)
  const $authentication = useStore(authentication_store.authentication)

  const [cartItems, setCartItems] = useState(0)
  const [user, setUser] = useState<(IAccount & IAuthentication) | null>(null)

  useEffect(() => {
    setCartItems($cart?.length || 0)
  }, [$cart])

  useEffect(() => {
    setUser($authentication)
  }, [$authentication])

  const handleNavigation = (ev: React.MouseEvent<HTMLAnchorElement>) => {
    ev.preventDefault()
    if (open) menuAtom.set(!open)
    navigate(ev.currentTarget.href)
  }

  const handleLogout = async (ev: React.MouseEvent<HTMLElement>) => {
    ev.preventDefault()
    if (open) menuAtom.set(!open)
    await fetch('/api/proxy/signout', { method: 'post' })
    authentication_action.signout()
  }

  const handleStudentArea = (ev: React.MouseEvent<HTMLElement>) => {
    ev.preventDefault()
    if (open) menuAtom.set(!open)
    if (user?.token) window.open(`${STUDENT_AREA_URL}?auth=${user?.token}`)
  }

  return (
    <header>
      <nav className="bg-zinc-900 text-zinc-50 fixed w-full z-30 top-0 left-0 text-sm border-b border-border/10">
        <div
          className="w-full h-1 bg-gradient-to-r from-zinc-500/20 to-zinc-500/60 absolute"
          style={{ display: 'none' }}
        >
          <div
            id="loading-bar"
            className="h-full bg-gradient-to-r from-amber-500 to-orange-500 rounded-r-xl transition-all ease-in-out duration-300"
            style={{ width: 0 }}
          />
        </div>
        <RotateCw
          id="loading-spinner"
          className="loading h-5 w-5 stroke-orange-400 animate-spin absolute -top-6 xl:top-1/3 right-5"
          style={{ display: 'none' }}
        />
        <div className="mx-auto max-w-7xl px-2 sm:px-4 lg:px-6 border-b border-zinc-600/30">
          <div className="relative flex h-16 items-center justify-between">
            <div className="flex flex-1 justify-between items-center">
              <div className="lg:hidden">
                <button
                  onClick={() => menuAtom.set(!$open)}
                  type="button"
                  className="relative inline-flex items-center justify-center p-2 text-white focus:outline-none"
                >
                  <span className="absolute -inset-0.5" />
                  <span className="sr-only">Open main menu</span>
                  {$open ? (
                    <X className="block h-6 w-6" aria-hidden="true" />
                  ) : (
                    <Menu className="block h-6 w-6" aria-hidden="true" />
                  )}
                </button>
              </div>

              <div className="flex-1 sm:flex-none">
                <a href="/">
                  <img
                    loading="eager"
                    src="/images/logo-white.webp"
                    height={30}
                    width={120}
                    alt="Logo do Direção Concursos"
                  />
                </a>
              </div>

              {/* 
              TODO: AWAIT UNTIL BETTER UX TO GLOBAL SEARCH
              <div className="hidden xl:flex">
                <Button
                  variant={'outline'}
                  className={cn(
                    'w-[160px] xl:w-[350px] pl-6 text-left text-xs bg-zinc-900 text-zinc-50 rounded-lg border-gray-600/30 hover:bg-zinc-800/30 hover:text-zinc-200 hover:border-zinc-500/30',
                  )}
                >
                  <span>Pesquisar...</span>
                  <Search className="ml-auto h-4 w-4 text-gray-600" />
                </Button>
              </div> */}

              <div className="flex items-center gap-2 md:gap-4">
                <UserDropdownMenu
                  user={user}
                  handleStudentArea={handleStudentArea}
                  handleLogout={handleLogout}
                />

                <Button
                  className="rounded-full cursor-pointer"
                  onClick={() => {}}
                  size="icon"
                  asChild
                  variant="ghost"
                >
                  <a href="/carrinho" aria-label="Carrinho">
                    <div>
                      {cartItems > 0 && (
                        <span className="absolute flex h-5 w-5 top-3 ml-3">
                          <span className="animate-ping absolute inline-flex h-full w-full rounded-full bg-blue-400 opacity-75" />
                          <span className="relative inline-flex text-xs items-center justify-center rounded-full h-5 w-5 bg-sky-500 text-white">
                            {cartItems > 9 ? '9+' : cartItems}
                          </span>
                        </span>
                      )}
                      <ShoppingBasket />
                    </div>
                  </a>
                </Button>
                <Button
                  variant="outline"
                  asChild
                  className="hidden px-4 lg:flex bg-transparent border-2 border-amber-500 hover:bg-amber-400/20 hover:text-white rounded-full text-xs"
                >
                  <a
                    href={SUBSCRIPTION}
                    target="_blank"
                    aria-label="Assinaturas"
                    rel="noreferrer"
                    className="inline-flex justify-between"
                  >
                    <span>Assine já</span>
                    <ChevronRight className="h-3 w-3 ml-2" />
                  </a>
                </Button>
              </div>
            </div>
          </div>
        </div>
        <div className="hidden mx-auto max-w-7xl lg:flex px-4 h-12">
          <div className="flex text-sm items-center space-x-6">
            <ul className="space-x-2 h-full font-medium text-sm hidden md:flex">
              {navigation.map(({ id, title, link, selected, children }) => (
                <li key={id} className={cn(children?.length && 'group')}>
                  {children?.length ? (
                    <>
                      <span className="h-full items-center justify-center text-sm font-medium transition-opacity text-zinc-300 hover:text-white duration-200 px-3 py-2 inline-flex cursor-pointer">
                        {title}
                        <ChevronDown className="h-3 w-3 ml-2 mt-0.5" />
                      </span>
                      <div className="absolute left-0 w-screen bg-zinc-900 shadow-2xl border-t border-zinc-300/[0.06] text-zinc-50 flex max-h-0 group-hover:max-h-[400px] overflow-hidden transition-all duration-300 ease-in-out">
                        <div className="px-4 pt-4 w-full max-w-7xl mx-auto flex flex-col space-y-3">
                          <ul
                            className={cn(
                              'w-full flex flex-1',
                              !children.every((c) => c.children.length > 0)
                                ? 'flex-col w-fit group vertical-ul'
                                : 'flex-row gap-4',
                            )}
                          >
                            {children.map(({ id, title, link, children }) => (
                              <li className="flex flex-col" key={id}>
                                {children?.length ? (
                                  <>
                                    <span className="text-white p-2 text-base font-semibold">
                                      {title}
                                    </span>
                                    <ul
                                      className={cn(
                                        'w-full grid',
                                        children.length > 20
                                          ? 'grid-cols-6 text-center'
                                          : children.length > 10
                                            ? 'grid-cols-2'
                                            : '',
                                      )}
                                    >
                                      {children.map(({ id, title, link }) => (
                                        <li
                                          key={id}
                                          className="hover:bg-orange-200/30 hover:text-amber-300 rounded-lg text-zinc-50/70 group-[.vertical-ul]:ml-2 w-fit"
                                        >
                                          <a
                                            className="px-2 py-2 flex space-x-2 items-center transition-opacity duration-200 text-xs font-normal"
                                            href={link}
                                          >
                                            {title}
                                          </a>
                                        </li>
                                      ))}
                                      {link && (
                                        <li
                                          key={id}
                                          className={cn(
                                            'hover:bg-cyan-200/30 hover:text-cyan-300 rounded-lg text-cyan-400 group-[.vertical-ul]:ml-2 underline w-fit',
                                            children.length > 20 &&
                                              'col-span-2',
                                          )}
                                        >
                                          <a
                                            className="px-2 py-2 flex space-x-2 items-center transition-opacity duration-200 text-xs font-normal"
                                            href={link}
                                          >
                                            Ver mais
                                            <ArrowUpRight className="h-4 w-4 ml-2" />
                                          </a>
                                        </li>
                                      )}
                                    </ul>
                                  </>
                                ) : (
                                  <a
                                    className="flex p-2 space-x-2 items-center duration-200 text-base font-medium hover:bg-orange-200/30 hover:text-amber-300 rounded-lg w-fit"
                                    href={link}
                                  >
                                    {title}
                                  </a>
                                )}
                              </li>
                            ))}
                          </ul>
                          <div className="flex justify-between gap-2 text-xs font-normal py-3 border-t border-zinc-300/[0.06]">
                            <div className="flex items-center gap-1">
                              <Button
                                variant="outline"
                                asChild
                                className="hidden px-3 lg:flex bg-transparent border-0 hover:bg-zinc-400/20 hover:text-white rounded-full text-[.6rem]"
                              >
                                <a
                                  href={SUBSCRIPTION}
                                  target="_blank"
                                  aria-label="Conheça nossas assinaturas"
                                  rel="noreferrer"
                                  className="inline-flex justify-between"
                                >
                                  <MessageCircleQuestion className="h-4 w-4 mr-1 text-zync-600" />
                                  Fale com o time comercial
                                </a>
                              </Button>
                            </div>
                            <div className="flex items-center gap-1">
                              <Button
                                variant="outline"
                                asChild
                                className="hidden px-3 lg:flex bg-transparent border-2 border-amber-500 hover:bg-amber-400/20 hover:text-white rounded-full text-[.6rem]"
                              >
                                <a
                                  href={SUBSCRIPTION}
                                  target="_blank"
                                  aria-label="Conheça nossas assinaturas"
                                  rel="noreferrer"
                                  className="inline-flex justify-between"
                                >
                                  <Zap className="h-4 w-4 mr-1 text-zync-600" />
                                  Conheça nossas assinaturas
                                </a>
                              </Button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </>
                  ) : (
                    <a
                      className={cn(
                        'h-full items-center justify-center text-sm font-medium px-3 py-2 inline-flex transition-opacity duration-200',
                        selected
                          ? 'text-zinc-200'
                          : 'text-zinc-300 hover:text-white',
                      )}
                      href={link}
                      key={id}
                    >
                      {title}
                    </a>
                  )}
                </li>
              ))}
            </ul>
          </div>
        </div>

        <div
          className={cn(
            'lg:hidden transition-all duration-300 h-screen',
            !$open && 'hidden',
          )}
        >
          <div className="space-y-1 px-2 pb-3 pt-2">
            <ul className="font-medium flex flex-col md:p-0 lg:flex-row lg:space-x-2 md:mt-0">
              {navigation.map(({ title, link, children }) => (
                <li key={title} className="group">
                  {children?.length ? (
                    <>
                      <button
                        type="button"
                        className="flex w-full justify-between items-center rounded-md px-3 py-2 text-base font-medium transition-colors focus:outline-none text-zinc-300 hover:bg-zinc-700 hover:text-white"
                      >
                        {title}
                        <ChevronDown
                          className="relative top-[1px] ml-1 h-3 w-3 transition duration-200 group-data-[state=open]:rotate-180"
                          aria-hidden="true"
                        />
                      </button>
                      <div
                        className={
                          'z-10 font-normal divide-y shadow w-full pl-4 max-h-0 group-hover:max-h-[300px] overflow-hidden transition-all duration-300 ease-in-out'
                        }
                      >
                        <ul
                          className="py-2 text-sm text-gray-700 dark:text-gray-400 w-full"
                          aria-labelledby="dropdownLargeButton"
                        >
                          {children.map(({ title, link }) => (
                            <a
                              key={title}
                              className="text-zinc-300 hover:bg-zinc-700 hover:text-white block rounded-md px-3 py-2 text-base font-medium"
                              onClick={handleNavigation}
                              href={link}
                            >
                              {title}
                            </a>
                          ))}
                        </ul>
                      </div>
                    </>
                  ) : (
                    <a
                      className="text-zinc-300 hover:bg-zinc-700 hover:text-white block rounded-md px-3 py-2 text-base font-medium"
                      onClick={handleNavigation}
                      href={link}
                    >
                      {title}
                    </a>
                  )}
                </li>
              ))}
              {user?.token && (
                <>
                  <li>
                    <div
                      className="text-zinc-300 hover:bg-zinc-700 hover:text-white flex justify-between items-center rounded-md px-3 py-2 text-base font-medium"
                      onClick={handleStudentArea}
                      onKeyDown={() => {}}
                    >
                      <span>Área do Aluno</span>
                      <SquareArrowOutUpRight
                        className="relative top-[1px] ml-1 h-3 w-3 transition duration-200 group-data-[state=open]:rotate-180"
                        aria-hidden="true"
                      />
                    </div>
                  </li>
                  <li className="flex mt-20 px-3 py-2">
                    <div
                      className="flex items-center justify-center border-primary border-2 text-center w-full text-primary hover:bg-zinc-700 hover:text-white rounded-md px-3 py-2 text-base font-medium"
                      onClick={handleLogout}
                      onKeyDown={() => {}}
                    >
                      <LogOut className="mr-2 h-4 w-4" />
                      <span>Sair</span>
                    </div>
                  </li>
                </>
              )}
            </ul>
          </div>
        </div>
      </nav>

      <div className="fixed w-screen h-screen backdrop-blur-md left-0 top-0 invisible opacity-0 transition-all duration-300 z-10" />
    </header>
  )
}
