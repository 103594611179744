import AccountForm from '@/components/core/account/account.form'
import {
  Avatar,
  AvatarFallback,
  AvatarImage,
} from '@/components/ui/atoms/avatar'
import { Button } from '@/components/ui/atoms/button'
import { getInitials } from '@/lib/utils'
import type { IAccount, IAuthentication } from '@/types/account'
import { LogOut, User, User2 } from 'lucide-react'

import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from '@/components/ui/atoms/popover'
import { Separator } from '@/components/ui/atoms/separator'
import { PopoverArrow } from '@radix-ui/react-popover'

export default function UserDropdownMenu({
  user,
  handleStudentArea,
  handleLogout,
}: {
  user?: null | (IAccount & IAuthentication)
  handleStudentArea: (ev: React.MouseEvent<HTMLDivElement, MouseEvent>) => void
  handleLogout: (ev: React.MouseEvent<HTMLDivElement, MouseEvent>) => void
}) {
  const initials = user?.name ? getInitials(user?.name) : null

  return (
    <>
      <Popover>
        <PopoverTrigger asChild>
          <Button
            variant="ghost"
            className="rounded-full w-10 p-2 bg-transparent no-arrow focus-visible:outline-0 focus-visible:ring-offset-0  focus-visible:ring-0"
          >
            {initials ? (
              <Avatar>
                <AvatarImage src={user?.avatar || ''} alt={user?.name} />
                <AvatarFallback className="bg-zinc-100 text-zinc-800">
                  {initials}
                </AvatarFallback>
              </Avatar>
            ) : (
              <User2 />
            )}
          </Button>
        </PopoverTrigger>
        <PopoverContent className="w-80 px-0 pb-2 border-0">
          {user?.token ? (
            <>
              <div className="flex flex-col space-y-1 px-4 pb-2">
                <p className="text-sm overflow-hidden text-ellipsis font-medium leading-none">
                  Olá, {user?.name}
                </p>
                <p className="text-xs overflow-hidden text-ellipsis text-muted-foreground">
                  {user?.email}
                </p>
              </div>
              <Separator className="my-1" />
              <div
                className="flex items-center mx-1 rounded-md px-4 py-3 cursor-pointer text-sm hover:bg-zinc-100"
                onClick={handleStudentArea}
                onKeyDown={() => {}}
              >
                <User className="mr-2 h-4 w-4" />
                <span>Área do Aluno</span>
              </div>
              <Separator className="my-1" />
              <div
                className="flex items-center mx-1 rounded-md px-4 py-3 cursor-pointer text-sm hover:bg-zinc-100"
                onClick={handleLogout}
                onKeyDown={() => {}}
              >
                <LogOut className="mr-2 h-4 w-4" />
                <span>Sair</span>
              </div>
            </>
          ) : (
            <div className="flex flex-col space-y-1">
              <AccountForm />
            </div>
          )}
          <PopoverArrow className="fill-white border-white stroke-white" />
        </PopoverContent>
      </Popover>
    </>
  )
}
